import root from './root';
import login from './login';
import groupTree from './groupTree';
import terminalDetect from './terminalDetect';
import organizationAndUser from './organizationAndUser';
import logs from './logs';
import rechargeSearch from './rechargeSearch';
import terminalManagement from './terminalManagement';
import messageRemind from './messageRemind';

export default {
    root,
    login,
    groupTree, // 企业组织树
    terminalDetect,
    organizationAndUser, // 组织与用户管理
    logs, // 日志查询
    rechargeSearch, // 充值查询
    terminalManagement, // 终端管理
    messageRemind, // 页面顶部消息提醒
};