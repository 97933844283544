/**
 * 路由Tabs组件
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Tabs, Tooltip} from 'antd';
import {SyncOutlined, FullscreenOutlined, FullscreenExitOutlined} from '@ant-design/icons';
import routerConfig from '../../utils/router/routerConfig';
import {getStore, setStore, removeStore} from '../../utils/localStorage';
import {menuIconConfig, getMenuList} from '../../utils/menuConfig';
import {push} from '../../utils/router/routeMethods';
import styles from './index.module.less';

const {TabPane} = Tabs;
// 刷新或关闭页面时处理路由数据
window.onbeforeunload = function (event) {
    event = event || window.event;
    if (event.clientX > document.body.clientWidth && event.clientY < 0 || event.altKey) {
        console.log('关闭页面');
        removeStore('routerList');
        removeStore('routerId');
        removeStore('childRouterList');
        removeStore('childRouterId');
    } else {
        console.log('刷新');
    }
    // return false;
};

class RouterTabBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initRouterList: [], // 初始路由列表
            routerList: [], // 路由列表
            routerId: '', // 当前路由code
            childRouterList: [], // 子路由列表
            childRouterId: '', // 子路由code
            requestFullscreenType: false, // 是否全屏显示
        };
    }

    data = {
        authorCode: [], // 用户权限列表
    }

    componentDidMount() {
        // eslint-disable-next-line react/prop-types
        const initRouterList = getStore('basic'); // 用户权限路由列表
        const defaultRouterList = getMenuList(); // 首页
        const newRouterList = getStore('routerList'); // 获取页面刷新时的路由列表
        const newRouterId = getStore('routerId'); // 获取页面刷新时的路由id
        const newChildRouterList = getStore('childRouterList'); // 获取页面刷新时的子路由列表
        const newChildRouterId = getStore('childRouterId'); // 获取页面刷新时的子路由id
        // eslint-disable-next-line max-len
        const newInitRouterList = this.menuListHandler(defaultRouterList.concat(JSON.parse(initRouterList)), routerConfig) || [];
        let routerList = [];
        let routerId = '';
        let childRouterList = [];
        let childRouterId = '';
        let childRouterIndex = 0;
        // 路由数据初始化
        routerList = newRouterList ? JSON.parse(newRouterList) : [newInitRouterList[0]];
        routerId = newRouterId ? newRouterId : newInitRouterList[0].code;
        childRouterList = newChildRouterList ? JSON.parse(newChildRouterList) : newInitRouterList[0].childPermissions;
        childRouterId = newChildRouterId ? newChildRouterId : newInitRouterList[0].childPermissions[0].code;
        // 获取刷新时子路由在列表中的下标
        for (let i = 0; i < childRouterList.length; i++) {
            if (childRouterList[i].code === childRouterId) {
                childRouterIndex = i;
                break;
            }
        }
        this.setState({
            initRouterList: newInitRouterList,
            routerList,
            routerId,
            childRouterList,
            childRouterId,
        }, () => {
            push(childRouterList[childRouterIndex].path);
        });
    }

    componentWillReceiveProps = (nextProps) => {
        const {activePathCode} = nextProps;
        const {initRouterList, routerList} = this.state;
        const newRouterList = JSON.parse(JSON.stringify(routerList));
        let routerId = '';
        let childRouterList = [];
        let childRouterId = '';
        if (activePathCode) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < initRouterList.length; i++) {
                const arr = initRouterList[i].childPermissions.filter(item => item.code === activePathCode);
                if (arr.length > 0) {
                    const newArr = routerList.filter(item => item.code === initRouterList[i].code);
                    if (newArr.length === 0) {
                        newRouterList.push(initRouterList[i]);
                    }
                    routerId = initRouterList[i].code;
                    childRouterList = initRouterList[i].childPermissions;
                    childRouterId = activePathCode;
                }

            }
            this.setState({
                routerList: newRouterList,
                routerId,
                childRouterList,
                childRouterId,
            }, () => {
                setStore('routerList', newRouterList);
                setStore('routerId', routerId);
                setStore('childRouterList', childRouterList);
                setStore('childRouterId', childRouterId);
            });
        }
    }

    // 1-menu数组组装
    menuListHandler = (menuList, rConfig) => menuList.map(
        item => this.listConfigHandler(item, rConfig),
    );

    // 2-
    listConfigHandler = (item, rConfig) => {
        let rItem = item;
        if (rItem.childPermissions) {
            const list = [];
            for (let i = 0; i < rItem.childPermissions.length; i += 1) {
                list.push(this.listConfigHandler(rItem.childPermissions[i], rConfig));
            }
            rItem = this.nodeHandler(rItem, rConfig);
            rItem.childPermissions = list;
            return rItem;
        }
        return this.nodeHandler(rItem, rConfig);
    }

    // 3-
    nodeHandler = (list, rConfig) => {
        let newItem = list;
        for (let i = 0; i < rConfig.length; i += 1) {
            if (rConfig[i].code === newItem.code) {
                this.authorMenuList(rConfig[i].code);
                newItem = rConfig[i];
                break;
            }
        }
        newItem.icon = menuIconConfig[newItem.code];
        return newItem;
    }

    // 4-用户权限页面code集合
    authorMenuList = (code) => {
        if (this.data.authorCode.indexOf(code) === -1) {
            this.data.authorCode.push(code);
        }
    }

    // 路由切换时
    onRouterChange = (activeKey) => {
        const {routerList} = this.state;
        let childRouterList = [];
        let childRouterId = '';
        const arr = routerList.filter(item => item.code === activeKey);
        if (arr.length > 0) {
            childRouterList = arr[0].childPermissions;
            childRouterId = arr[0].childPermissions[0].code;
        }
        this.setState({
            routerId: activeKey,
            childRouterList,
            childRouterId,
        }, () => {
            push(childRouterList[0].path);
        });
    }

    // 删除路由时
    onRouterEdit = (targetKey, action) => {
        console.log('onRouterEdit', targetKey, action);
        const {routerList, routerId} = this.state;
        let newRouterId = routerId;
        let lastIndex = 0;
        let childRouterList = [];
        let childRouterId = '';
        let pushUrl = '';
        routerList.forEach((item, i) => {
            if (item.code === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newRouterList = routerList.filter(item => item.code !== targetKey);
        if (newRouterList.length && newRouterId === targetKey) {
            if (lastIndex >= 0) {
                newRouterId = newRouterList[lastIndex].code;
                childRouterList = routerList[lastIndex].childPermissions;
                childRouterId = routerList[lastIndex].childPermissions[0].code;
            } else {
                newRouterId = newRouterList[0].code;
                childRouterList = routerList[0].childPermissions;
                childRouterId = routerList[0].childPermissions[0].code;
            }
            pushUrl = childRouterList[0].path;
        }
        this.setState({
            routerList: newRouterList,
            routerId: newRouterId,
            childRouterList,
            childRouterId,
        }, () => {
            push(pushUrl);
        });
    }

    // 子路由切换时
    onChildRouterChange = (activeKey) => {
        const {childRouterList} = this.state;
        let routerIndex = 0;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < childRouterList.length; i++) {
            if (childRouterList[i].code === activeKey) {
                routerIndex = i;
            }
        }
        this.setState({
            childRouterId: activeKey,
        }, () => {
            push(childRouterList[routerIndex].path);
        });
    }

    // 刷新
    onReload = () => {
        // 刷新浏览器方法
        location.reload();
    }

    // 全屏（true 开启全屏，false 关闭全屏）
    onRequestFullscreen = (type) => {
        const allDom = document.documentElement; // 整个网页
        const oDom = document.getElementById(""); // 某个页面元素
        if (!type) {
            // 判断各种浏览器，找到正确的启动全屏方法
            if (allDom.requestFullscreen) {
                allDom.requestFullscreen();
            } else if (allDom.mozRequestFullScreen) { /* Firefox */
                allDom.mozRequestFullScreen();
            } else if (allDom.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                allDom.webkitRequestFullscreen();
            } else if (allDom.msRequestFullscreen) { /* IE/Edge */
                allDom.msRequestFullscreen();
            }
        } else {
            // 判断各种浏览器，找到正确的关闭全屏方法
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
            }
        }
        let newRequestFullscreenType = !type;
        this.setState({
            requestFullscreenType: newRequestFullscreenType
        });
    }

    render() {
        const {routerList, routerId, childRouterList, childRouterId, requestFullscreenType} = this.state;
        // eslint-disable-next-line react/prop-types
        const {collapsed} = this.props;
        // console.log('routerList', routerList);
        // console.log('routerId', routerId);
        // console.log('childRouterList', childRouterList);
        // console.log('childRouterId', childRouterId);
        return (
            <Tabs
                className={styles.tabs}
                activeKey={routerId}
                hideAdd
                type='editable-card'
                onChange={this.onRouterChange}
                onEdit={this.onRouterEdit}
            >
                {
                    routerList.map(item => (
                        <TabPane tab={item.name} key={item.code} closable={item.closable}>
                            {/** 二级路由 */}
                            <div className={styles.wrap}>
                                <Tabs
                                    className={styles.sonTabs}
                                    activeKey={childRouterId}
                                    onChange={this.onChildRouterChange}
                                >
                                    {
                                        childRouterList.map(childItem => (
                                            <TabPane tab={childItem.name} key={childItem.code}/>
                                        ))
                                    }
                                </Tabs>
                                <div className={styles.smallTools}>
                                    <Tooltip title='刷新'>
                                        <SyncOutlined
                                            className={styles.iconHover}
                                            style={{fontSize: 20}}
                                            onClick={this.onReload}
                                        />
                                    </Tooltip>
                                    <Tooltip title='全屏'>
                                        {
                                            requestFullscreenType
                                                ? <FullscreenExitOutlined
                                                    className={styles.iconHover}
                                                    style={{fontSize: 20}}
                                                    onClick={() => {
                                                        this.onRequestFullscreen(requestFullscreenType)
                                                    }}
                                                />
                                                : <FullscreenOutlined
                                                    className={styles.iconHover}
                                                    style={{fontSize: 20}}
                                                    onClick={() => {
                                                        this.onRequestFullscreen(requestFullscreenType)
                                                    }}
                                                />
                                        }
                                    </Tooltip>
                                </div>
                            </div>
                        </TabPane>
                    ))
                }
            </Tabs>
        );
    }
}

export default connect(
    state => ({
        collapsed: state.rootReducers.collapsed,
    }),
)(RouterTabBar);
