import axios from '../utils/server/axios';

/**
 * 充值查询
 */
const orderManagement = {
    // 获取列表
    getOrderList: data => axios('/api/order/management/list', 'POST', data),
    // 获取详情
    getDetail: (id) => axios(`/api/order/management/${id}`, 'GET'),
    //新增
    addOrder: data => axios('/api/order/management', 'POST', data),
    //修改
    editOrder: data => axios('/api/order/management/edit', 'POST', data),
    //批量修改
    batchEditOrder: data => axios('/api/order/management/batch/edit', 'POST', data),
    //新增客户
    addCustomer: data => axios('/api/order/management/customer', 'POST', data),
    //获取客户列表
    getCustomerList: data => axios('/api/order/management/customer/list', 'GET', data),
    // 删除订单
    delOrder: (id) => axios(`/api/order/management/${id}`, 'DELETE'),
    // 批量删除订单
    batchDelOrder: (data) => axios('/api/order/management/batch/delete', 'POST', data),
    // 获取列表
    exportOrderList: data => axios('/api/order/management/list/export', 'POST', data, 8000, 'blob'),
};

export default orderManagement;